import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect  } from 'react';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// Sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';

const TABLE_HEAD = [
  { id: 'nombre', label: 'Nombre', alignRight: false },
  { id: 'archivo', label: 'archivo', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [editedData, setEditedData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openShowModal, setOpenShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [apiData, setApiData] = useState([]);
  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch("https://swserviceapp.mac-softins.com/api/chanels");
      if (response.ok) {
        const data = await response.json();
        setApiData(data); // Actualiza los datos de la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };
  useEffect(() => {
    fetchDataFromAPI(); // Llama a la función para obtener datos de la API cuando se monta el componente
  }, []);
  return (
    <>
      <Helmet>
        <title> M@c-IpTV | cha </title>
      </Helmet>

      <Container>
      <Typography variant="h4" sx={{ mb: 5, textAlign: 'center'  }} >
        Nuestras Canales
      </Typography>
      <Grid container spacing={3}>
        {apiData.map((data) => (
          <Grid item xs={12} sm={6} md={3}>
          <Link to={`/mactv/chanelto/${data.nombre}`} style={{ textDecoration: 'none' }}>
            <div style={{ backgroundColor: '#e8ebeea1', color: '#000', textAlign: 'center', padding: '10px',borderRadius:'10px' }}>
              <img src={data.img} alt="Ícono de descarga en Play Store" style={{ width: '15%', height: 'auto', margin: 'auto' }} />
              <h5>{data.nombre}</h5>
            </div>
           </Link>
          </Grid>
        ))}
      </Grid>

      </Container>
    </>
  );
}
