import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {Grid, Container, Typography } from '@mui/material';
// hooks
import { Link,useParams } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const { id } = useParams();
  const [apiData, setApiData] = useState([]);

  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch(`https://swserviceapp.mac-softins.com/api/categoritoweb/${id}`);
      if (response.ok) {
        const data = await response.json();
        setApiData(data); // Actualiza los datos de la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]);

  return (
    <>
      <Helmet>
        <title> M@c IpTV | categoría canales </title>
      </Helmet>

      <Container>
      <Typography variant="h4" sx={{ mb: 5, textAlign: 'center'  }} >
        Nuestras Canales
      </Typography>
      <Grid container spacing={3}>
        {apiData.map((data) => (
          <Grid item xs={12} sm={6} md={3}>
          <Link to={`/mactv/chanelto/${data.nombre}`} style={{ textDecoration: 'none' }}>
            <div style={{ backgroundColor: '#e8ebeea1', color: '#000', textAlign: 'center', padding: '10px',borderRadius:'10px' }}>
              <img src={data.img} alt="Ícono de descarga en Play Store" style={{ width: '15%', height: 'auto', margin: 'auto' }} />
              <h5>{data.nombre}</h5>
            </div>
           </Link>
          </Grid>
        ))}
      </Grid>

      </Container>
    </>
  );
}
