import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Footer = styled('footer')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

function getCurrentYear() {
  return new Date().getFullYear();
}

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const handleOpenLink = () => {
    window.open('https://mac-softins.com/', '_blank');
  };

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
      <Footer>
        &copy; {getCurrentYear()}todos los derechos reservados de {' '}
        <a href="https://mac-softins.com/" onClick={handleOpenLink} target="_blank" rel="noopener noreferrer">
          M@-Softins
        </a>
      </Footer>
    </StyledRoot>
  );
}
