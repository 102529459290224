// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'inicio',
    path: '/mactv/app',
    icon: icon('ic_home'),
  },
  {
    title: 'canales',
    path: '/mactv/chanels',
    icon: icon('ic_recoder'),
  },
  {
    title: 'peliculas',
    path: '/mactv/movie',
    icon: icon('ic_video'),
  },

];

export default navConfig;
