import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  function isAuthenticated() {
    return localStorage.getItem('isAuthenticated') === 'true';
  }
  function Logoutuser() {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('responseData');
    return true;
  }

  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(null);
  };
  const cerrarsecccion = () => {
    const loguut=Logoutuser();
    navigate('/login');
  };
  const inicio = () => {
    navigate('/');
  };
  const profile=()=>{
    navigate('/dashboard/Perfil');
  }
  return (
    <>


    </>
  );
}
