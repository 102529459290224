import React, {useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Grid, Container, Typography } from '@mui/material';
import Slider from 'react-slick';
import VideoCover from 'react-video-cover';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Importa los componentes necesarios (asegúrate de que los nombres sean correctos)
import {
  AppWidgetSummary,
  // Agrega aquí otros componentes según sea necesario
} from '../sections/@dashboard/app';

export default function DashboardAppPage() {
  const theme = useTheme();
  const [apiData, setApiData] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    // Aquí puedes controlar el slider si es necesario
  }, []);

  const videos = [
    'https://rath.mac-softins.com/video/v1.mp4',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch("https://swserviceapp.mac-softins.com/api/getcategori");
      if (response.ok) {
        const data = await response.json();
        setApiData(data); // Actualiza los datos de la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };
  useEffect(() => {
    fetchDataFromAPI();
  }, []);
  return (
    <>
      <Helmet>
        <title>Television en linea</title>
      </Helmet>
      <Container maxWidth="xl" style={{ position: 'relative', height: '80vh', overflow: 'hidden', marginTop: '-6%' }}>
        <Slider {...settings} ref={sliderRef} style={{ marginTop: '-10%' }}>
          {videos.map((video, index) => (
            <div key={index}>
              <VideoCover
                videoOptions={{ src: video, autoPlay: true, muted: true, loop: true }}
                remeasureOnWindowResize
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                }}
              />
              <Container maxWidth="xl" style={{ position: 'relative', height: '70vh',overflow: 'hidden' }}>
                <Grid container spacing={2} style={{ position: 'absolute', width: '90%', bottom: 0 }}>
                <Grid item xs={6} sm={6} md={6}>
                  <a href="https://play.google.com/store/apps/details?id=com.iptvmac.app&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div style={{ backgroundColor: '#e8ebeea1', color: '#fff', textAlign: 'center', padding: '10px',borderRadius:'10px' }}>
                      <img src="https://www.svgrepo.com/show/217136/playstore.svg" alt="Ícono de descarga en Play Store" style={{ width: '15%', height: 'auto', margin: 'auto' }} />
                      <h5> Descargar en Play Store</h5>
                    </div>
                  </a>
                </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Link to={`/mactv/movie`} style={{ textDecoration: 'none' }}>
                      <div style={{ backgroundColor: '#e8ebeea1', color: '#fff', textAlign: 'center', padding: '10px',borderRadius:'10px' }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/5362/5362522.png" alt="Ícono de descarga en Play Store" style={{ width: '15%', height: 'auto', margin: 'auto' }} />
                        <h5>Ver peliculas y series en liena</h5>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            </div>
          ))}
        </Slider>
      </Container>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5, textAlign: 'center'  }} >
          Nuestras Categorias
        </Typography>
        <Grid container spacing={3}>
          {apiData.map((data) => (
            <Grid item xs={12} sm={6} md={3} key={data.name}>
              <Link to={`/mactv/categorito/${data.name}`} style={{ textDecoration: 'none' }}>
                <div style={{ backgroundColor: '#e8ebeea1', color: '#000', textAlign: 'center', padding: '10px',borderRadius:'10px' }}>
                  <img src={data.img} alt="Ícono de descarga en Play Store" style={{ width: '15%', height: 'auto', margin: 'auto' }} />
                  <h5>{data.name}</h5>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
