import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import VideoPage from './pages/VideoPage';
import LibrosPage from './pages/LibrosPage';
import Potcast from './pages/Potcast';
import LoginPage from './pages/LoginPage';
import Profile from './pages/Profile';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/mactv/app" replace />, // Redirige a /login por defecto
    },
    {
      path: '/mactv',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/mactv/app" />},
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'chanels', element: <Potcast /> },
        { path: 'movie', element: <UserPage /> },
        { path: 'chanelto/:id', element: <VideoPage /> },
        { path: 'categorito/:id', element: <Profile /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/mactv/app" /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
