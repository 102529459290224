import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Typography,
  CardContent,
  Grid,
  CardMedia,
  CardActionArea,
} from '@mui/material';

export default function UserPage() {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const apiKey = '2ddf556eeb914248e655f1bdfb288c27'; // Reemplaza con tu propia API key
    const apiUrl = `https://api.themoviedb.org/3/discover/movie?api_key=${apiKey}&language=es-ES`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setMovies(data.results))
      .catch((error) => console.error('Error fetching movies:', error));
  }, []);

  return (
    <>
      <Helmet>
        <title> M@c IpTV | Peliculas </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          Lista de películas
        </Typography>

        <Grid container spacing={3}>
          {movies.map((movie) => (
            <Grid item key={movie.id} xs={12} sm={6} md={3}>
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={movie.title}
                    height="140"
                    image={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {movie.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {movie.overview}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
