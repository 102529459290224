import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect  } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// Sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';

const TABLE_HEAD = [
  { id: 'nombre', label: 'Nombre', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'option', label: 'Option', alignRight: true },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

  const { id } = useParams();
  const [streamerUrl, setStreamerUrl] = useState('');

  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch(`https://swserviceapp.mac-softins.com/api/getchanelweb/${id}`);
      if (response.ok) {
        const data = await response.json();
        setStreamerUrl(data.streamer); // Actualiza la URL de la transmisión desde la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [id]); // Asegúrate de que fetchDataFromAPI se ejecute cada vez que cambia el ID

  return (
    <>
      <Helmet>
        <title> M@c-IpTV | Chanel </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {id}
          </Typography>
        </Stack>
        <div>
        <ReactPlayer
          url={streamerUrl}
          controls
          playing
          width="100%"
          height="auto"
        />
        </div>
      </Container>
    </>
  );
}
