import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect  } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// Sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';

const TABLE_HEAD = [
  { id: 'nombre', label: 'Nombre', alignRight: false },
  { id: 'categoria', label: 'categoria', alignRight: false },
  { id: 'PDF', label: 'PDF', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [editedData, setEditedData] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openShowModal, setOpenShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [apiData, setApiData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [onDocumentLoadSuccess, setOnDocumentLoadSuccess] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchDataFromAPI = async () => {
    try {
      const response = await fetch("https://swserviceapp.mac-softins.com/api/listlibro");
      if (response.ok) {
        const data = await response.json();
        setApiData(data); // Actualiza los datos de la API
      } else {
        throw new Error("Error en la solicitud");
      }
    } catch (error) {
      console.error("Error al obtener datos de la API:", error);
    }
  };
  useEffect(() => {
    fetchDataFromAPI(); // Llama a la función para obtener datos de la API cuando se monta el componente
  }, []);
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(apiData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const EditOpenModal = (id) => {
      // Buscar los datos que corresponden al ID seleccionado en apiData
      const dataToEdit = apiData.find((item) => item.id === id);
      console.log(dataToEdit);
      // Establecer los datos en el estado local para que el modal los muestre
      setEditedData(dataToEdit);

      // Abrir el modal de edición
      setOpenEditModal(true);
    };
    const handleSubmit = () => {
      // Crear un objeto FormData y agregar los datos del formulario
      const formData = new FormData();
      formData.append("nombre", document.getElementById("name").value);
      formData.append("categoria", document.getElementById("categoria").value);
      formData.append("file", document.querySelector('input[type="file"]').files[0]);

      // Realizar la solicitud POST a la API
      fetch("https://swserviceapp.mac-softins.com/api/savelibro", {
        method: "POST",
        body: formData,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error en la solicitud");
      })
        .then((data) => {
          console.log("Respuesta de la API:", data);
            fetchDataFromAPI();
          // Realizar acciones adicionales si es necesario
          handleCloseModal();
        })
        .catch((error) => {
          console.error("Error al enviar la solicitud a la API:", error);
        });
    };
    const handleEditSubmit = () => {
      // Crear un objeto FormData y agregar los datos del formulario
      const formData = new FormData();
      formData.append("nombre", document.getElementById("name_edit").value);
      formData.append("categoria", document.getElementById("categoria_edit").value);
      formData.append("id", document.getElementById("id_edit").value);
      formData.append("file", document.querySelector('input[type="file"]').files[0]);

      // Realizar la solicitud POST a la API
      fetch("https://swserviceapp.mac-softins.com/api/editlibro", {
        method: "POST",
        body: formData,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Error en la solicitud");
      })
        .then((data) => {
          console.log("Respuesta de la API:", data);
            fetchDataFromAPI();
          // Realizar acciones adicionales si es necesario
          setOpenEditModal();
        })
        .catch((error) => {
          console.error("Error al enviar la solicitud a la API:", error);
        });
    };
    const handleOpenShowModal = (id) => {
      // Encuentra el objeto con el ID correspondiente en tu arreglo de datos
      const selectedData = apiData.find((item) => item.id === id);
      console.log(selectedData);

      if (selectedData) {

        const ruta = selectedData.file;
        const partes = ruta.split('/');
        console.log(partes);
        const pdfUrl = `https://swserviceapp.mac-softins.com/api/pdf/${partes[2]}`;
        console.log(pdfUrl);
        fetch(pdfUrl)
          .then(response => response.blob())
          .then(blob => {
            console.log(blob);
          })
          .catch(error => console.error('Error al cargar el PDF:', error));
        setPdfUrl(pdfUrl);
        setModalData(selectedData); // Almacena los datos en el estado para mostrarlos en el modal
        setOpenShowModal(true); // Abre el modal de visualización
      }
    };
    const deletedata=(id)=>{
    fetch(`https://swserviceapp.mac-softins.com/api/deletelibro/${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          fetchDataFromAPI(); // Asegúrate de tener una función para cargar los datos
        } else {
          throw new Error('Error al eliminar el elemento');
        }
      })
      .catch((error) => {
        console.error('Error al eliminar el elemento:', error);
      });

  }
  return (
    <>
      <Helmet>
        <title> Bethel | Postcast </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Libros Y Textos
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenModal}>
            Nuevo Libro o Texto
          </Button>
        </Stack>

        <Card>
                 <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                 <Scrollbar>
                   <TableContainer sx={{ minWidth: 800 }}>
                     <Table>
                       <UserListHead
                         order={order}
                         orderBy={orderBy}
                         headLabel={TABLE_HEAD}
                         rowCount={apiData.length}
                         numSelected={selected.length}
                         onRequestSort={handleRequestSort}
                         onSelectAllClick={handleSelectAllClick}
                       />
                       <TableBody>
                         {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           const { id, name, categoria, file,estado } = row;
                           const selectedUser = selected.indexOf(name) !== -1;

                           return (
                             <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                               <TableCell padding="checkbox">
                                 <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                               </TableCell>

                               <TableCell component="th" scope="row" padding="none">
                                 <Stack direction="row" alignItems="center" spacing={2}>
                                   <Typography variant="subtitle2" noWrap>
                                     {name}
                                   </Typography>
                                 </Stack>
                               </TableCell>
                               <TableCell component="th" scope="row" padding="none">
                                 <Stack direction="row" alignItems="center" spacing={2}>
                                   <Typography variant="subtitle2" noWrap>
                                     {categoria}
                                   </Typography>
                                 </Stack>
                               </TableCell>
                               <TableCell align="left">{file}</TableCell>
                               <TableCell align="left">{estado}</TableCell>
                               <TableCell align="right">
                                 <IconButton size="large" color="error"  onClick={() => deletedata(id)} >
                                   <Iconify icon={'eva:trash-2-outline'} />
                                 </IconButton>
                                 <IconButton size="large" color="warning" onClick={() => EditOpenModal(id)}>
                                   <Iconify icon={'eva:edit-fill'} />
                                 </IconButton>
                                 <IconButton size="large" color="success" onClick={() => handleOpenShowModal(id)}>
                                   <Iconify icon={'eva:eye-fill'} />
                                 </IconButton>
                               </TableCell>
                             </TableRow>
                           );
                         })}
                         {emptyRows > 0 && (
                           <TableRow style={{ height: 53 * emptyRows }}>
                             <TableCell colSpan={6} />
                           </TableRow>
                         )}
                       </TableBody>

                       {isNotFound && (
                         <TableBody>
                           <TableRow>
                             <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                               <Paper
                                 sx={{
                                   textAlign: 'center',
                                 }}
                               >
                                 <Typography variant="h6" paragraph>
                                   Not found
                                 </Typography>

                                 <Typography variant="body2">
                                   No results found for &nbsp;
                                   <strong>&quot;{filterName}&quot;</strong>.
                                   <br /> Try checking for typos or using complete words.
                                 </Typography>
                               </Paper>
                             </TableCell>
                           </TableRow>
                         </TableBody>
                       )}
                     </Table>
                   </TableContainer>
                 </Scrollbar>

                 <TablePagination
                   rowsPerPageOptions={[5, 10, 25]}
                   component="div"
                   count={apiData.length}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   onPageChange={handleChangePage}
                   onRowsPerPageChange={handleChangeRowsPerPage}
                 />
               </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Nuevo Postcast</DialogTitle>
        <DialogContent>
          <form>
          <TextField fullWidth label="Nombre" id="name" name="name" />
            <TextField fullWidth label="Categoria" id="categoria" name="categoria" />
            <input type="file" id="file" name="file" />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
     <DialogTitle>Editar Postcast</DialogTitle>
     <DialogContent>
       <form>
          <input type="hidden" id="id_edit" name="id_edit"  value={editedData ? editedData.id : ''} onChange={(e) => {
            setEditedData({
              ...editedData,
              id: e.target.value,
            });
          }}
        />
         <TextField fullWidth label="Nombre" id="name_edit" name="name_edit" value={editedData ? editedData.name : ''}
        onChange={(e) => {
          setEditedData({
            ...editedData,
            name: e.target.value,
          });
        }}/>
        <TextField fullWidth label="Nombre" id="categoria_edit" name="categoria_edit" value={editedData ? editedData.categoria : ''}
       onChange={(e) => {
         setEditedData({
           ...editedData,
           categoria: e.target.value,
         });
       }}/>
         <input type="file" id="file" name="file" />
       </form>
     </DialogContent>


     <DialogActions>
       <Button onClick={() => setOpenEditModal(false)} color="primary">
         Cancelar
       </Button>
       <Button onClick={handleEditSubmit} color="primary">
         Guardar
       </Button>
     </DialogActions>
   </Dialog>
   <Dialog open={openShowModal} onClose={() => setOpenShowModal(false)}>
     <DialogTitle>Detalles del Postcast</DialogTitle>
     <DialogContent>
       <Typography variant="subtitle1">Nombre: {modalData && modalData.name}</Typography>
       <Typography variant="subtitle1">Potcast {modalData && modalData.file}</Typography>
       <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
         <Page pageNumber={pageNumber} />
       </Document>
     </DialogContent>
     <DialogActions>
       <Button onClick={() => setOpenShowModal(false)} color="primary">
         Cerrar
       </Button>
     </DialogActions>
   </Dialog>
    </>
  );
}
